import React, { useState, useEffect } from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import { getFeature } from '../../constants';
import ContactFrom from '../ContactForm';
import Map from '../Map';
import './index.css';


function SplitTemplate(props) {

    return (
        <Container className="page-container">
            <Row>
                <Col md={6} sm={12} className="left-col">
                    <h1 className="page-title" >{props.settings.title}</h1>
                    { props.settings.left.map((feature) => {
                        return getFeature(feature);
                    })
                    }

                </Col>
                <Col md={6} sm={12} className="right-col">
                    { props.settings.right.map((feature) => {
                        return getFeature(feature);
                    })
                    }

                </Col>
            </Row>
        </Container>
    );
}

export default SplitTemplate;