import React, { useState, useEffect } from 'react';
import Split from '../components/templates/split';
import {Contact} from '../constants';

function ContactPage() {
  return (
    <Split settings={Contact} />
  );
}

export default ContactPage;