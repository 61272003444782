import { MultiDirectedGraph } from "graphology";
import {Col, Container, Row} from "react-bootstrap";
import { SigmaContainer } from "@react-sigma/core";
import "@react-sigma/core/lib/react-sigma.min.css";
import { NetEffects } from "../components/NetEffects";

import '../App.css';

export const Innovation = () => {
  // Create the graph
  const graph = new MultiDirectedGraph();
  graph.addNode("A", { x: 1, y: 2, label: "Node A", size: 25 });
  graph.addNode("B", { x: 0, y: 0, label: "Node B", size: 15 });
  graph.addNode("C", { x: 2, y: 0, label: "Node C", size: 15 });
  graph.addEdgeWithKey("rel1", "A", "B", { label: "REL_1" });
  graph.addEdgeWithKey("rel2", "A", "C", { label: "REL_2" });
  graph.addEdgeWithKey("rel3", "B", "C", { label: "REL_3" });

  return (
    <Container className="page-container">
        <Row>
            <Col md={6}>
                <h1 className="page-title">Innovation</h1>
                <p>On top of relational and unstructured databases, Eclectec also specializes in graph databases, graph analysis, finding
                    connections in unstructured data and visualizations.
                </p>
                <h3>Why Graph Databases</h3>
                <ul>
                    <li>Speedy complex queries of unstructured data</li>
                    <li>Discovery of complex / hidden relationships</li>
                    <li>Graph analysis of data including
                        <ul>
                            <li>Popularity</li>
                            <li>Similarity</li>
                            <li>Centrality</li>
                            <li>Shortest Path</li>
                        </ul>
                    </li>
                </ul>
                <h3>Knowledge Graphs</h3>
                <p>
                    Knowledge graph visualizations aide with displaying graph database collections. Algorithms and layouts can help identify
                    pertinent data points.  
                </p>
            </Col>
            <Col md={6} className="net-graph">
                <SigmaContainer style={{height: 350, background: "none"}} graph={graph} settings={{ allowInvalidContainer: true, defaultNodeColor: "#61dafb" }}>
                    <NetEffects/>
                </SigmaContainer>
            </Col>
        </Row>
        <Row>
            <Col>
                
            </Col>
        </Row>
    </Container>
  )
};