import { Link } from "react-router-dom";
import { Col, Container, Row } from 'react-bootstrap';
import './index.css';

function Footer() {
  return (
    <div className="footer">
    <Container>
      <Row>
        <Col>
          <img src="/coming_soon.png" alt="ECLECTEC" />
          <p>Henderson, Nevada</p>
        </Col>
        <Col>
          <ul>
            <li><Link className='nav-link' to='/about'>About</Link></li>
            <li><Link className='nav-link' to='/careers'>Careers</Link></li>
            <li><Link className='nav-link' to='/contact-us'>Contact</Link></li>
          </ul>
        </Col>
        <Col>Copyright &#169; 2024 ECLECTEC LLC</Col>
      </Row>
    </Container>
    </div>
  );
}

export default Footer;