import React, { useState } from 'react';
import {Button, Form} from 'react-bootstrap';
import Axios from 'axios';
import './index.css';



function ContactForm() {

    const [formFields, setFormFields] = useState({});
    const [filled, setIsFilled] = useState(false);
    const [validated, setValidated] = useState(false);

    const handleChange = (event) => {
        const value = event.target.name === "robot" ? !formFields.robot : event.target.value;
        const formObject = {
            ...formFields,
            [event.target.name]: value
        };

        setIsFilled(isValid(formObject));
        setFormFields(formObject);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setValidated(true);
        if(isValid){
            submitForm();
        }
    };

    const isValid = (contactForm) => {
        if(Object.keys(contactForm).length >= 4 &&
            contactForm.name.length > 0 && 
            contactForm.email.length > 2 && 
            contactForm.message.length > 2 && 
            contactForm.robot) {
                return true;
        } else {
            return false;
        }
    }

    const submitForm = () => {
        Axios.post('/contact/index.php', formFields).then((response) => {
            console.log(response)
        });
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Control type="text" name="name" value={formFields.name} onChange={handleChange} placeholder="Name" required />
            <Form.Control type="email" name="email" value={formFields.email} onChange={handleChange} placeholder="Email" required />
            <Form.Control as="textarea" name="message" value={formFields.message} onChange={handleChange} placeholder="Message" style={{height: "100px"}} required/>
            <Form.Check
                type="checkbox"
                name="robot"
                checked={formFields.robot}
                onChange={handleChange}
                label="I'm not a robot"
                required
            />
            <Button className={!filled ? 'invalid' : ''} type="submit">Submit</Button>
        </Form>
    );
}

export default ContactForm;