import {
  BrowserRouter as Router,
  Routes,
  Route } from 'react-router-dom';
import Header from './components/header';
import Home from './pages/home';
import About from './pages/about';
import Careers from './pages/careers';
import Contact from './pages/contact';
import { AI } from './pages/ai';
import { Innovation } from './pages/innovation';
import { Processing } from './pages/processing';
import Footer from './components/footer';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/ai" element={<AI />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/innovation" element={<Innovation />} />
        <Route path="/processing" element={<Processing />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;