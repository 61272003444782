import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './index.css';

function Header() {
  return (
    <Navbar expand="lg" className="">
      <Container>
        <Navbar.Brand href="/"><img src="/logo.png" alt="ECLECTEC" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Link className='nav-link' to='/about'>About</Link>
            <Link className='nav-link' to='/careers'>Careers</Link>
            <Link className='nav-link' to='/contact-us'>Contact</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;