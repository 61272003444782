import {Col, Row} from 'react-bootstrap';
import Map from '../components/Map';
import ContactForm from '../components/ContactForm';

export const getFeature = (feature) => {
    let tags = [];
    const width = 12 / Object.keys(feature).length;
    for(const key of Object.keys(feature)) {
        if(key === 'title') {
            tags.push (<h1 className="page-title" key={feature.title}>{feature.title}</h1>);
        } else if(key === 'text') {
            tags.push (<Col xs={width}><p>{feature.text}</p></Col>)
        } else if(key === 'image') {
            tags.push (<Col xs={width}><img src={feature.image} style={{width: '100%'}}/></Col>)
        } else if (key === 'list') {
            tags.push (
                <Col xs={width}>
                    <ul>
                        {feature.list.map((item) => (<li>{item}</li>))}
                    </ul>
                </Col>
            );
        } else if (key === 'map') {
            tags.push(<Map positions={feature.map} />)
        } else if (key === 'form') {
            tags.push(<ContactForm />)
        }
    }
    return <Row>{tags}</Row>;
}

export const About = {
    title: 'About',
    template: 'split',
    left: [
        {text: 'Eclectec is a small business that specializes in developing innovative software solutions. Based in Henderson Nevada, Eclectec offers an array of services including:'},
        {list: ['Software development', 'Data analytics', 'Machine Learning / Natural Language Processing (nlp)', 'Data Streaming', 'Desktop Applications', 'And many more...']}
    ],
    right: [
        {image: '/henderson.jpg'}
    ]
};

export const Careers = {
    title: 'Careers',
    template: 'full',
    content: [
        {text: 'Coming soon...'}
    ]
};

export const Contact = {
    title: 'Contact',
    template: 'split',
    left: [
        {form: 'contact'}
    ],
    right: [
        {map: [[36.0395, -114.9817]]}
    ]
};