import React, { useState, useEffect } from 'react';
import Split from '../components/templates/split';
import {About} from '../constants';

function AboutPage() {
  return (
    <Split settings={About} />
  );
}

export default AboutPage;