import React, { useState } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './index.css';



function Map() {
    const position = [36.0395, -114.9817];
    const icon = L.icon({ iconUrl: '/marker-icon.png' });


    return (
        <MapContainer center={position} zoom={13} scrollWheelZoom={false} style={{height: '500px'}}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position} icon={icon}>
            {/* <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
            </Popup> */}
            </Marker>
        </MapContainer>
    );
}

export default Map;