import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {Card, Carousel, Col, Container, Row} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrain, faCogs, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import './index.css';

function HomeTemplate() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
      setIndex(selectedIndex);
    };


    return (
        <div>
            <div className="home-container">
                <Row>
                    <Carousel activeIndex={index} onSelect={handleSelect}>
                        <Carousel.Item>
                            <img src="/nlp.jpg" alt="Natural Language Processing" />
                            {/* <Carousel.Caption>
                            <h3>First slide label</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                            </Carousel.Caption> */}
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src="/solutions.jpg" alt="Innovation / prototyping" />
                            <Carousel.Caption>
                                <h3>INNOVATION</h3>
                                {/* <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src="/data.jpg" alt="Data processing / analysis" />
                            <Carousel.Caption>
                                <h3>PROCESSING</h3>
                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </Row>
                <Container className="services">
                    <Row>
                        <Col md={4} sm={12}>
                            <Link to="/ai">
                                <Card>
                                    <Card.Body>
                                        <Card.Title><FontAwesomeIcon icon={faBrain} /></Card.Title>
                                        <Card.Text>
                                            AI / ML
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col md={4} sm={12}>
                            <Link to="/innovation">
                                <Card>
                                    <Card.Body>
                                        <Card.Title><FontAwesomeIcon icon={faLightbulb} /></Card.Title>
                                        <Card.Text>
                                            INNOVATION
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col md={4} sm={12}>
                            <Link to="/processing">
                                <Card>
                                    <Card.Body>
                                        <Card.Title><FontAwesomeIcon icon={faCogs} /></Card.Title>
                                        <Card.Text>
                                            PROCESSING
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className="tech">
                <Row>
                    <Col md={2} xs={4}><img src="/logos/openai.png" title="" alt=""/></Col>
                    <Col md={2} xs={4}><img src="/logos/spacy.png" title="" alt=""/></Col>
                    <Col md={2} xs={4}><img src="/logos/spark.png" title="" alt=""/></Col>
                    <Col md={2} xs={4}><img src="/logos/pytorch.png" title="" alt=""/></Col>
                    <Col md={2} xs={4}><img src="/logos/arango.png" title="" alt=""/></Col>
                    <Col md={2} xs={4}><img src="/logos/docker.png" title="" alt=""/></Col>
                    {/* <Col xs={8}>
                        <p>
                            Eclectec is a small software development company in Henderson Nevada. 
                            The company specializes in rapidly prototyping software solutions and streamlining workflows.
                        </p>
                    </Col> */}
                </Row>
            </Container>
        </div>
    );
}

export default HomeTemplate;