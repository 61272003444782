import {Col, Container, Row} from "react-bootstrap";
import "@react-sigma/core/lib/react-sigma.min.css";

import '../App.css';

export const AI = () => {

  return (
    <Container className="page-container">
            <Row>
                <Col md={8}>
                    <h1 className="page-title">AI</h1>
                    <p>
                        AI encompasses many different fields. Eclectec focuses on two cost effective branches... Machine Learning and Natural Language Processing.
                    </p>
                    <h3>Machine Learning (ML)</h3>
                    <p>
                        Machine learning in simple terms is generating models from training data and utilizing the model for pattern matching. Many frameworks can be utilized
                        to accomplish this goal. Eclectec specialize in incorporating models from both Torch and Spark.
                    </p>
                    <h3>Natural Language Processing (NLP)</h3>
                    <img id="pipeline" src="/pipeline.svg" title="Natural Language Processing" alt="Natural Language Processing" />
                    <p>
                        Natural language processing utilizes machine learning to enable computers to understand and communicate with human language. Models are able to be
                        trained to understand categories of people / places / things and identify relationships in text. Eclectec specializes in extracting information from text
                        and generating insight from the data.
                    </p>
                </Col>
                <Col md={4} >
                    <img src="/logos/spark.png" alt="Spark" title="Spark" />
                    <img src="/logos/pytorch.png" alt="PyTorch" title="PyTorch" />
                    <img src="/logos/spacy.png" alt="spaCy" title="spaCy" />
                </Col>
            </Row>
        </Container>
  )
};