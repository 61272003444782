import {Col, Container, Row} from "react-bootstrap";
import "@react-sigma/core/lib/react-sigma.min.css";

import '../App.css';

export const Processing = () => {

  return (
    <Container className="page-container">
            <Row>
                <Col xs={8}>
                    <h1 className="page-title">Processing</h1>
                    <p>
                        Information is everywhere and can be piped in to any application to enrich the user experience. Eclectec can create middle ware to bridge the gap.
                    </p>
                    <h3>Services:</h3>
                    <ul>
                        <li>Data Scrapers</li>
                        <li>API Requests</li>
                        <li>Audio & Video</li>
                        <li>Streaming</li>
                    </ul>
                </Col>
                <Col xs={4} >

                </Col>
            </Row>
        </Container>
  )
};