import React, { useState, useEffect } from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import { getFeature } from '../../constants';
import './index.css';


function FullTemplate(props) {

    return (
        <Container className="page-container">
            <Row>
                <Col xs={12}>
                    <h1 className="page-title" >{props.settings.title}</h1>
                    {props.settings.content.map((feature) => {
                        return getFeature(feature);
                      })
                    }
                </Col>
            </Row>
        </Container>
    );
}

export default FullTemplate;