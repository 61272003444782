import React, { useState, useEffect } from 'react';
import Full from '../components/templates/full';
import {Careers} from '../constants';

function CareersPage() {
  return (
    <Full settings={Careers} />
  );
}

export default CareersPage;